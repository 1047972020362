import { IncomingMessage } from 'http';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetServerSidePropsResult } from 'next';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import { initializeApolloClient } from '@crehana/apollo-client';
import { TAppProps } from '@crehana/next';

import ME_AUTH_CONTEXT from '@/shared/graphql/MeAuthContext.v2.graphql';

export type MarketpalceUserAuthorizationCallback<T> = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
) => Promise<GetServerSidePropsResult<TAppProps<T>>>;

type Props<T> = {
  callback: MarketpalceUserAuthorizationCallback<T>;
  pageKey: string;
  org: string;
  req: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
};

export const marketplaceUserAuthorization = async <T>({
  req,
  pageKey,
  org,
  callback,
}: Props<T>): Promise<GetServerSidePropsResult<TAppProps<T>>> => {
  const apolloClient = initializeApolloClient({
    headers: req.headers,
    pageKey,
  });

  const { data, error } = await apolloClient.query({
    query: ME_AUTH_CONTEXT,
    context: { clientName: 'v2' },
  });

  if (!data.me || error)
    return {
      redirect: { destination: `/org/${org}/ecommerce/`, permanent: false },
    };

  return await callback(apolloClient);
};
